//css-------------------
import "../../css/share/common.scss";
import "../../css/home/welcome.scss";

//swiper
import(/* webpackPrefetch: true */ "swiper/css");
import(/* webpackPrefetch: true */ "swiper/css/pagination");
import(/* webpackPrefetch: true */ "swiper/css/navigation");

//fontawesome
import(/* webpackPrefetch: true */ "../../lib/fontawesome/fontawesome.scss");
import(/* webpackPrefetch: true */ "../../lib/fontawesome/regular.scss");
import(/* webpackPrefetch: true */ "../../lib/fontawesome/brands.scss");
import(/* webpackPrefetch: true */ "../../lib/fontawesome/solid.scss");

//js----------------------
//components
// import( /* webpackPrefetch: true */ '../share/components/_cookie_bar');
import(/* webpackPrefetch: true */ "../share/components/_font_family");
import(/* webpackPrefetch: true */ "../share/components/_welcome_lazy");

//npm lib
import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import imagesLoaded from "imagesloaded";
import Swiper from "swiper";
import { Pagination, Navigation } from "swiper/modules";
import { createApp } from "vue/dist/vue.esm-browser.prod.js";
import Parallax from "parallax-js";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

const app = createApp({
    data() {
        return {
            menu_open: false,
            url: null,
            member: {
                id: null,
                name: null,
                avatar: null,
            },
            form: {
                name_1: null,
                name_2: null,
                mobile_1: null,
                mobile_2: null,
                address: null,
                email: null,
                title: null,
                org: null,
                org_intro: null,
                description: null,
                recipient: null,
                file: null,
                img_1: null,
                img_2: null,
                img_3: null,
                img_4: null,
                img_5: null,
                submit: 0,
                google_recaptcha: null,
            },
            form_confirm: false,
            description_count: 200,
            org_intro_count: 200,
            store: false,
            store_status: false,
            store_result: false,
            request: {
                submit: {
                    confirm: false,
                    uploading: false,
                    status: null,
                },
                save: {
                    uploading: false,
                    status: null,
                },
            },
            result_row_show: false,
            input_completed: true,
            member_fb_data: null,
            fb_connected: null,
            judge_row: judge_row,
            submission_row: submission_row,
            vote_msg: null,
            lb_data: null,
            lb_swiper: null,
        };
    },
    watch: {
        menu_open: function (_menu_open) {
            if (_menu_open) {
                gsap.to(".header_btn,.auth_row", {
                    duration: 0.2,
                    opacity: 0,
                    display: "none",
                    ease: "power1.out",
                });
                gsap.to(".nav_bg", {
                    duration: 0.4,
                    opacity: 1,
                    display: "block",
                    ease: "quart.out",
                });
            } else {
                gsap.to(".header_btn,.auth_row", {
                    duration: 0.2,
                    opacity: 1,
                    display: "block",
                    ease: "power1.out",
                });
                gsap.to(".nav_bg", {
                    duration: 0.2,
                    opacity: 0,
                    display: "none",
                    ease: "quart.out",
                });
            }
        },
    },
    mounted() {
        this.page_ready_init();
        this.kv_parallax();
        this.page_scrollTrigger();
        this.fb_login_sdk();
        console.log(submission_row);
    },
    methods: {
        vote_handler(submission_item) {
            const _this = this;
            if (
                this.fb_connected == "not connected" ||
                (this.fb_connected == "connected" && !this.member.name)
            ) {
                this.lightbox_open("lb_vote_login", null);
            } else {
                if (!this.form.google_recaptcha) return;
                if (!confirm('您確定要將本日票數投給《' + submission_item.title + '》？')) return;
                $.ajax({
                    url: _app_domain + "/member/vote/ajax",
                    headers: { "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"), },
                    method: "post",
                    data: {
                        google_recaptcha: this.form.google_recaptcha,
                        user_id: this.member.id,
                        submission_id: submission_item.id,
                    },
                    dataType: "json",
                    beforeSend: function () {
                        _this.vote_msg = null;
                        _this.lightbox_open("lb_vote_msg", null);
                    },
                    success: function (response, status) {
                        _this.vote_msg = response.msg;
                        submission_item.num = submission_item.num + 1;
                    },
                    error: function (response, status) {
                        _this.vote_msg = "error";
                    },
                }).always(function (response, status) {
                    console.log(response, status);
                });
            }
        },
        google_recaptcha_handler() {
            const _this = this;
            grecaptcha.ready(function () {
                grecaptcha.execute(_RECAPTCHA_SITE_KEY).then(function (token) {
                    _this.form.google_recaptcha = token;
                });
            });
        },
        page_ready_init() {
            imagesLoaded("html, body", function () {
                gsap.to(".loading", {
                    duration: 0.3,
                    opacity: 0,
                    display: "none",
                });
            });
        },
        fb_login_sdk() {
            const _this = this;
            window.fbAsyncInit = function () {
                FB.init({
                    appId: "3759352517670089",
                    xfbml: true,
                    version: "v20.0",
                });
                FB.AppEvents.logPageView();
                _this.facebook_check_login_state();
            };

            (function (d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            })(document, "script", "facebook-jssdk");
        },
        page_scrollTrigger() {
            gsap.registerPlugin(ScrollTrigger);

            gsap.timeline({
                scrollTrigger: {
                    trigger: ".schedule_sec",
                    start: "top 70%",
                },
            })
                .to(".step_car", {
                    duration: 3,
                    x: "100vw",
                    ease: "power0.out",
                })
                .set(".step_car", { x: -600 })
                .to(".step_car", { duration: 2, x: 0, ease: "quart.out" });
        },
        kv_parallax() {
            if ($(window).width() > 950) {
                new Parallax(document.getElementById("scene"));
            }
        },
        member_destroy_alert(e) {
            e.preventDefault();
            if (!confirm("確認刪除？")) return;

            window.location.href = $(e.target).attr("href");
        },
        nav_handler(to_sec) {
            this.menu_open = false;
            gsap.to("html,body", {
                duration: 1,
                scrollTop: $("#" + to_sec).offset().top,
                ease: "quart.out",
            });
        },
        text_count(column, model_count) {
            const _text_count = column.length;
            this[model_count] = 200 - _text_count;
        },
        form_check() {
            const _this = this;
            const _check_array = [
                "user_deleted",
                "name_2",
                "mobile_2",
                "img_1",
                "img_2",
                "img_3",
                "img_4",
                "img_5",
                "submit",
                "submit_time",
                "sort",
                "updated_by",
            ];

            _this.input_completed = true;

            Object.entries(this.form).forEach(function (key, value) {
                if ($.inArray(key[0], _check_array) == -1) {
                    if (!key[1]) {
                        console.log(key[0]);
                        _this.input_completed = false;
                    }
                }
            });
        },
        form_submit() {
            this.form_check();

            if (this.form.submit) {
                alert("您已報名完成，如需修改資料請撥打服務專線：0900-441-256");
                return false;
            }

            if (!this.input_completed) {
                alert("尚有必填欄位未填");
                return false;
            }

            if (!confirm("確認送交？送交後將不能再修改囉")) return;

            this.request.submit.confirm = true;
            this.form_save();
        },
        form_save() {
            const _this = this;

            this.form_check();

            if (this.form.submit) {
                alert("您已報名完成，如需修改資料請撥打服務專線：0900-441-256");
                return false;
            }
            if (!this.form_confirm) {
                alert("請先閱讀且同意「活動注意事項」及「隱私聲明」");
                return false;
            }

            if (this.request.submit.confirm) {
                this.form.submit = 1;
            }

            $.ajax({
                url: _app_domain + "/member/submission/ajax",
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                method: "post",
                data: _this.form,
                dataType: "json",
                beforeSend: function () {
                    _this.result_row_show = false;

                    if (_this.request.submit.confirm) {
                        _this.request.submit.uploading = true;
                    } else {
                        _this.request.save.uploading = true;
                    }
                    _this.request.save.status = null;
                    _this.request.submit.status = null;
                },
                success: function (response, status) {
                    _this.request.save.status = true;

                    if (_this.request.submit.confirm) {
                        _this.request.submit.status = true;
                    }
                },
                error: function (response, status) {
                    _this.request.save.status = false;
                    _this.request.submit.status = false;
                    if (response.responseJSON.errors.google_recaptcha) {
                        alert("機器人驗證未過，請刷新頁面再試一次");
                    }
                },
            }).always(function (response, status) {
                // console.log(response, status);

                _this.result_row_show = true;

                if (_this.request.submit.confirm) {
                    _this.request.submit.uploading = false;
                } else {
                    _this.request.save.uploading = false;
                }
            });
        },
        file_upload(file, column) {
            const _this = this;
            const formData = new FormData();
            formData.append("file", file);
            formData.append("column", column);
            $.ajax({
                url: _app_domain + "/member/file/upload",
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                contentType: false,
                processData: false,
                mimeType: "multipart/form-data",
                method: "post",
                data: formData,
                dataType: "json",
                beforeSend: function () {
                    _this.form[column] = {
                        path: null,
                        size: null,
                        name: null,
                        uploading: true,
                    };
                },
                success: function (response, status) {
                    _this.form[response.column].path = response.path;
                    _this.form[response.column].size = response.size;
                    _this.form[response.column].name = response.name;
                    _this.form[response.column].uploading = false;
                    // console.log(response.column + ':' + response.path);
                },
                error: function () {
                    gsap.to("#lb_error", {
                        duration: 0.4,
                        opacity: 1,
                        display: "flex",
                        ease: "circ.out",
                    });
                },
            }).always(function (response, status) {
                console.log(response, status);
            });
        },
        on_pdf_change(e) {
            const file = e.target.files[0];
            this.file_upload(file, "file");
        },
        on_img_change(e, n) {
            const file = e.target.files[0];
            this.file_upload(file, "img_" + n);
        },
        facebook_logout() {
            const _this = this;
            FB.logout(function (response) {
                console.log(response);
                _this.member.name = null;
            });
        },
        facebook_login() {
            const _this = this;
            FB.login(function (response) {
                // console.log(response);
                _this.facebook_check_login_state();
            });
        },
        laravel_login() {
            const _this = this;

            $.ajax({
                url: _app_domain + "/member/login",
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                method: "post",
                data: _this.member_fb_data,
                success: function (res, status) {
                    _this.fb_connected = "connected";
                    switch (res.msg) {
                        case "need register":
                            if (
                                confirm("您的facebook帳號尚未註冊，是否註冊成為會員？")
                            ) {
                                _this.member_fb_data.action = "register";
                                _this.laravel_login();
                            }
                            break;
                        case "login success":
                            const _res = res.request;
                            _this.member.id = res.user.id != undefined ? res.user.id : null;
                            _this.member.name = _res.name != undefined ? _res.name : null;
                            _this.member.avatar = _res.picture != undefined ? _res.picture.data.url : null;
                            if (_res.submission) {
                                _this.form = _res.submission;
                            }
                            _this.google_recaptcha_handler();
                            break;
                        case "login failed":
                            alert("登入失敗，請刷新頁面後再試一次");
                            break;
                        default:
                            console.log("login failed");
                    }
                },
            }).always(function (response, status) {
                console.log(response, status);
            });
        },
        facebook_check_login_state() {
            const _this = this;
            FB.getLoginStatus(function (response) {
                if (response.status === "connected") {
                    FB.api(
                        "/me",
                        { fields: "name,picture,email" },
                        function (response) {
                            _this.member_fb_data = response;
                            _this.member_fb_data.action = "login";
                            _this.laravel_login();
                            gsap.to("#lb_vote_login", {
                                duration: 0.2,
                                opacity: 0,
                                display: "none",
                                ease: "circ.out",
                            });
                        }
                    );
                } else {
                    _this.fb_connected = "not connected";
                    _this.member.name = null;
                    _this.member.avatar = null;
                }
                // console.log(_this.fb_connected);
            });
        },
        lightbox_open(lb, data) {
            const _this = this;

            this.lb_data = null;
            if (data) {
                this.lb_data = data;
            }

            gsap.to("#" + lb, {
                duration: 0.4,
                opacity: 1,
                display: "flex",
                ease: "circ.out",
                onComplete: function () {
                    if (data) {
                        _this.lb_swiper = new Swiper('.lb_swiper_' + data.id + ' .swiper', {
                            modules: [Pagination, Navigation],
                            slidesPerView: 1,
                            grabCursor: true,
                            initialSlide: 0,
                            pagination: {
                                el: '.lb_swiper .swiper-pagination',
                                clickable: true,
                            },
                            navigation: {
                                nextEl: '.lb_swiper .swiper-button-next',
                                prevEl: '.lb_swiper .swiper-button-prev',
                            },
                        });
                    }
                }
            });

        },
        lightbox_close(e) {
            if (
                $(e.target).is(".lightbox") ||
                $(e.target).is(".btn_close_link")
            ) {
                gsap.to(".lightbox", {
                    duration: 0.2,
                    opacity: 0,
                    display: "none",
                    ease: "circ.out",
                });

                if (this.lb_swiper) {
                    this.lb_swiper.destroy();
                }
            }
        },
    },
}).mount("#app");
